import { FeatureFlag, useGetFeatureFlagsQuery } from "graphql/rails-api";
import React, { createContext, useContext } from "react";

interface FeatureFlagInterface {
  features: FeatureFlag[];
  isLoading: boolean;
  isFeatureEnabled(feature: string): boolean;
  loadingComponent?: React.ReactNode;
}

const FeatureFlagContext = createContext<FeatureFlagInterface>({
  features: [],
  isLoading: false,
  isFeatureEnabled: () => false,
  loadingComponent: undefined
});

const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error("useFeatureFlag must be used within FeatureFlagProvider");
  }

  return context;
};

const FeatureFlagProvider: React.FC<{
  children: React.ReactNode;
  loadingComponent?: React.ReactNode;
}> = ({ children, loadingComponent }) => {
  const { data, loading } = useGetFeatureFlagsQuery({
    context: { clientName: "rails-api" },
    fetchPolicy: "cache-first"
  });

  const features = data?.getFeatureFlags || [];
  const isFeatureEnabled = (feature: string): boolean =>
    !!features.find((f) => f.feature === feature && f.enabled);

  return (
    <FeatureFlagContext.Provider
      value={{
        features,
        isLoading: loading,
        isFeatureEnabled,
        loadingComponent
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagContext, FeatureFlagProvider, useFeatureFlag };
